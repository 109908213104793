import i18n from '../../../core/i18n';
import services from '../../../core/services';
import maintenance from '../api/maintenance';
import { IAlertsService } from './../../../platform/services/types';
import { IMaintenanceService } from './types';

/**
 * Maintenance Service
 */
export class MaintenanceService implements IMaintenanceService {
  /**
   * Get site maintenance information.
   * @returns maintenance information.
   */
  async getMaintenanceInformation(): Promise<unknown> {
    try {
      const res = await maintenance.getMaintenanceInformation();
      return res.body;
    } catch (err) {
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('admin.platform.maintenance.error.retrieve_maintenance') as string);

      throw err;
    }
  }

  /**
   * Turns the  site offline (activate maintenance).
   * @param message - Maintenance message
   */
  async turnSiteOffline(message: string): Promise<unknown> {
    try {
      const res = await maintenance.turnSiteOffline(message);
      return res.body;
    } catch (err) {
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('admin.platform.maintenance.error.turn_offline_save_failed') as string);

      throw err;
    }
  }

  /**
   * Turns the  site online (deactivate maintenance).
   */
  async turnSiteOnline(): Promise<unknown> {
    try {
      const res = await maintenance.turnSiteOnline();
      return res.body;
    } catch (err) {
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('admin.platform.maintenance.error.turn_online_save_error') as string);

      throw err;
    }
  }
}
