import services from '../../../core/services';
import Permissions from '../constants/permissions';

const routes = [
  {
    path: '/admin',
    name: '',
    component: () => import('../layouts/Layout'),
    children: [
      {
        path: 'platform',
        name: 'adminPlatform',
        component: () => import('../views/AdminPlatform'),
        meta: {
          permission: Permissions.PLATFORM,
        },
      },
      {
        path: 'poster',
        name: 'adminPoster',
        component: () => import('../views/AdminPoster'),
        meta: {
          permission: Permissions.POSTER,
        },
      },
      {
        path: '',
        redirect: () => {
          const adminRoutes = routes[0];
          const firstAuthorizedRoute = adminRoutes.children.find(
            (route) =>
              route.path &&
              (!route.meta ||
                !route.meta.permission ||
                services.getService('auth').hasPermission(route.meta.permission))
          );
          if (firstAuthorizedRoute) {
            return firstAuthorizedRoute.path;
          } else {
            return { name: 'adminPlatform' };
          }
        },
      },
    ],
  },
];

export default routes;
