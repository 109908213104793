import Vue from 'vue';

import { Response } from './../../../core/types/http';

/**
 * Applications API.
 */
const API_URL_BASE = '/api/piivo/';

export default {
  /**
   * Get site maintenance information.
   * @returns maintenance information.
   */
  async getMaintenanceInformation(): Response<unknown> {
    return Vue.http.get(`${API_URL_BASE}maintenance`) as Response<unknown>;
  },
  /**
   * Turns the  site offline (activate maintenance).
   * @param message - Maintenance message
   */
  async turnSiteOffline(message: string): Response<unknown> {
    return Vue.http.post(`${API_URL_BASE}offline`, JSON.stringify(message)) as Response<unknown>;
  },
  /**
   * Turns the  site online (deactivate maintenance).
   */
  async turnSiteOnline(): Response<unknown> {
    return Vue.http.post(`${API_URL_BASE}online`) as Response<unknown>;
  },
};
