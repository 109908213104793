import Vue from 'vue';

import { Response } from '../../../../core/types/http';

/**
 * Signage caches API.
 */
const API_URL_BASE = '/api/signages/cache';

export default {
  /**
   * Clear all signage caches.
   */
  async clearAllCaches(): Response<unknown> {
    return Vue.http.post(`${API_URL_BASE}/clear/all`) as Response<unknown>;
  },
};
