import './static/styles/_all.scss';

import { getExtensionPoint } from '../../core/extensionPoints';
import AdminRoutes from './routes';
import { addAdminService } from './services';
import { CachesService } from './services/cachesService';
import { MaintenanceService } from './services/maintenanceService';
import { AdminModule } from './store';

const moduleName = 'admin';

/**
 * Function to install admin app module
 * */
function install(platformExtension, useCache) {
  platformExtension.router.addRoutes(AdminRoutes);
  platformExtension.registerStoreModule(moduleName, AdminModule);

  addAdminService('cachesService', new CachesService());
  addAdminService('maintenanceService', new MaintenanceService());
}

getExtensionPoint('platform').services.getService('moduleManager').registerModule({
  name: moduleName,
  install,
});
