import i18n from '../../../core/i18n';
import services from '../../../core/services';
import caches from '../api/poster/posterCaches';
import { IAlertsService } from './../../../platform/services/types';
import { ICachesService } from './types';

/**
 * Poster Admin Caches Service
 */
export class CachesService implements ICachesService {
  /**
   * Clear all signage caches.
   */
  async clearAllCaches(): Promise<unknown> {
    try {
      const res = await caches.clearAllCaches();
      return res.body;
    } catch (err) {
      // Alert error
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('admin.poster.caches.clear.error') as string);

      throw err;
    }
  }
}
